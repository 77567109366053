<script setup lang="ts">
import { useUtilsApi } from '@/composables/api/utilsApi'
import { useAccountApi } from '@/composables/api/accountApi'

const emit = defineEmits(['cancel'])
const { fetchAccountData } = useAccountApi()
const { data: authData } = useAuth()
const {
  notify,
} = useUtilsApi()
const { successToast } = useToast()
const { t } = useI18n()

const { data: responsiblePersons, isFetching: loadingData } = useQuery({
  queryKey: ['fetchAccountData'],
  queryFn: async () => {
    return await fetchAccountData()
  },
  select: ({ data }) => data?.customer?.responsiblePersons as GenericObjectI[] ?? [],
})

const contactForm = ref()

const { mutateAsync: sendNotification } = useMutation({
  mutationFn: async (message) => {
    return await notify(message)
  },
  onSuccess: () => {
    successToast(t('notify.message-sent'))
  },
})

const personsOptions = computed(() => {
  return responsiblePersons.value?.map(person => ({
    label: person.name,
    value: { id: person.id, name: person.name },
    // value: person.id,
    picture: person.picture,
    type: person.type,
  })) ?? []
})

async function sendMessage(data) {
  const { person, subject, message } = data
  const fullName = `${authData.value?.user?.contact?.name} ${authData.value?.user?.contact?.lastName}`
  const customerName = authData.value?.customer?.name ?? ''
  const mainSubject = `AvantPortal: New message from ${fullName}`

  const model = {
    type: 4,
    entity_id: person.id,
    entity_name: 'User',
    function_name: 'getEmail',
    subject: mainSubject,
    template: 'message',
    variables: {
      title: subject,
      message,
      name: person.name,
      contact: {
        name: fullName,
        customer: customerName,
        email: null,
      },
      project: {
        type: '',
        idNumber: null,
        id: null,
      },
      actions: null,
    },
  }

  // TODO: Improve this empty catch (technical debt)
  try {
    await sendNotification(model)
    emit('cancel')
  }
  catch (error) {
  }
}
</script>

<template>
  <div v-if="!loadingData && responsiblePersons.length">
    <FormKit
      ref="contactForm"
      type="form"
      :actions="false"
      @submit="sendMessage"
    >
      <FormKit
        type="dropdown"
        name="person"
        validation="required"
        :label="t('shared.actions.contact', 0)"
        :placeholder="t('shared.form.select-person')"
        popover
        :options="personsOptions"
        inner-class="!w-full !max-w-full"
      >
        <template #option="{ option, classes }">
          <div :class="`${classes.option} flex items-center`">
            <img
              :src="option.picture"
              alt="optionAvatar"
              class="mr-2 rounded-full w-6 h-6"
            >
            <span>
              {{ option.label }} ({{ option.type }})
            </span>
          </div>
        </template>
      </FormKit>

      <FormKit
        type="text"
        :label="t('shared.form.subject')"
        wrapper-class="my-7 mb-9"
        name="subject"
        :placeholder="t('shared.placeholder.subject')"
        validation="required"
        inner-class="!w-full !max-w-full"
      />
      <FormKit
        type="editor"
        name="message"
        :placeholder="t('shared.placeholder.message')"
        validation="required"
        editor-style="height: 200px"
      />
      <div class="w-full flex justify-end mt-6">
        <FormKit
          type="submit"
          :label="t('shared.actions.send')"
        />
      </div>
    </FormKit>
  </div>
</template>
